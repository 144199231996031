<template>
  <div>
    <v-tabs
      align-with-title
    >
      <v-tabs-slider color="accent"></v-tabs-slider>
      <!-- Todo - update for payroll -->
        <v-tab
          :to="{ name: 'Warrant/Payroll Register - Fund Reconciliation' }"
        >
        Fund Reconciliation
        </v-tab>
      <v-tabs-slider color="accent"></v-tabs-slider>
        <v-tab
          :to="{ name: 'Warrant/Payroll Register - Create Fiscal Year' }"
        >
        Create New Fiscal Year
        </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'WarrantsAppropriationsUtils',
  components: {
  },
  created() {
    this.loadConfig({ requiredScope: 'warconfig' });
  },
  computed: {
    ...mapState({
      globalSettingItem: (state) => state.SystemConfig.globalSettingItem,
    }),
    isButtonVisible() {
      const { countyName } = this.globalSettingItem;
      const isFakeEnv = (countyName || '').match(new RegExp('fake', 'gi'));
      if (isFakeEnv) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions('SystemConfig', ['loadConfig']),
  },
};
</script>
